/**
 * Permite mostrar una alerta de confirmacion
 * @param title - titulo
 * @param description - descripcion
 * @param icon - icono
 * @param confirm_callback - funcion de confirmacion
 * @param confirm_btn_text - texto del boton de confirmacion
 * @param cancel_btn_text - texto del boton de negacion
 */
function confirm_dialog(title, description, icon, confirm_callback, confirm_btn_text = 'Confirmar', cancel_btn_text = 'Cancelar') {
    Swal.fire({
        title: title,
        text: description,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirm_btn_text,
        cancelButtonText: cancel_btn_text
    }).then((result) => {
        if (result.isConfirmed) {
            confirm_callback()
        }
    })
}

/**
 * Permite mostrar una alerta de confirmacion con 2 callback
 * @param title - titulo
 * @param description - descripcion
 * @param icon - icono
 * @param confirm_callback - funcion de confirmacion
 * @param confirm_btn_text - texto del boton de confirmacion
 * @param cancel_btn_text - texto del boton de negacion
 */
function confirm_dialog_two_actions(title, description, icon, confirm_callback, cancel_callback, confirm_btn_text = 'Confirmar', cancel_btn_text = 'Cancelar', allowOutsideClick = false) {
    Swal.fire({
        title: title,
        text: description,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirm_btn_text,
        cancelButtonText: cancel_btn_text,
        allowOutsideClick: allowOutsideClick
    }).then((result) => {
        if (result.isConfirmed) {
            confirm_callback()
        } else {
            cancel_callback();
        }
    })
}

/**
 * Permite mostrar una alerta basica en pantalla
 * @param title
 * @param description
 * @param icon
 */
function basic_dialog(title, description, icon) {
    Swal.fire(
        title,
        description,
        icon
    )
}


/**
 * Permite mostrar una alerta basica en pantalla, con accion callback
 * @param title
 * @param description
 * @param icon
 */
function callback_dialog(title, description, icon, confirm_callback) {
    Swal.fire({
            title: title,
            text: description,
            icon: icon,
            allowOutsideClick: false
        }
    ).then((result) => {
        if (result.isConfirmed) {
            confirm_callback()
        }
    })
}


function three_buttons_dialog(title, description, icon, confirm_callback, deny_callback, confirm_btn_text = 'Sí', deny_btn_text = 'No', cancel_btn_text = 'Cancelar') {

    Swal.fire({
        title: title,
        text: description,
        icon: icon,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: confirm_btn_text,
        cancelButtonText: cancel_btn_text,
        denyButtonText: deny_btn_text,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            confirm_callback()
        } else if (result.isDenied) {
            deny_callback()
        }
    })
}


/**
 * muestra una notificación en la pantalla
 * @param type - success, error, warning, info
 * @param msg - mensaje a mostrar, puede ser html
 * @inheritDoc https://github.com/naoxink/notifIt
 */
function show_notification(type, msg) {
    notif({
        type: type,
        msg: msg,
        position: "center",
        // width: 500,
        // height: 60,
        autohide: false,
        multiline: true,
    });
}

/**
 * Permite transformar un valor nuemrico a un valor monetario COP
 * @param amount
 * @returns {string}
 */
function currency_format(amount) {
    const options2 = {style: 'currency', currency: 'COP'};
    const numberFormat2 = new Intl.NumberFormat('es-CO', options2);
    return numberFormat2.format(amount)
}

/**
 * Permite obtener el numero de dias que tiene un mes
 * @param year
 * @param month
 * @returns {number}
 */
function getMonthDays(year, month) {
    return new Date(year, month, 0).getDate();
}

/**
 * Permite obtener el formato de fecha local sin hora a partir de una fecha
 * @param date
 * @returns {string|*}
 */
function localStringDate(date) {
    let date_instance = new Date(date)
    if (isCorrectDate(date_instance))
        return date_instance.toLocaleDateString()
    return date.split(' ')[0]
}

/**
 * Permite obtener el formato de fecha local con hora a partir de una fecha
 * @param date
 * @returns {string|*}
 */
function localString(date) {
    let date_instance = new Date(date)
    if (isCorrectDate(date_instance))
        return date_instance.toLocaleString()
    return date
}


/**
 * Permite verificar si una fecha es valida
 * @param date
 * @returns {boolean}
 */
function isCorrectDate(date) {
    return date instanceof Date && isFinite(date);

    // return date instanceof Date && !isNaN(date);
}

/**
 * Permite relizar una redireccion a codeigneter
 * @param to
 */
function ci_redirect(to) {
    $.ajax({
        method: "GET",
        url: base_url + "api/auth/ci",
        headers: {
            Authorization: "Bearer " + localStorage.getItem('access_token')
        },
        beforeSend: function (xhr) {
            $("#global-loader").fadeIn("fast");
        }
    }).done(function (data) {
        let response = data.data;
        if (data.status === 'success') {

            window.location.href = ci_base_url + `main/laravelLogin?user=${response.uar_id}&${response.query}&to=${to}`;
        } else {
            basic_dialog('Error', data.message, 'error')
        }
    }).always(function () {
        $("#global-loader").fadeOut("slow");
    });
}

/*
*permite hacer toggle a un modal por medio de su id
*/
function closeModal(idModal){
    $('#'+idModal).modal('toggle')
}
